import Vue from "vue";
import App from "./App.vue";
import "./plugins/vuetify-money.ts";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import Vue2Filters from "vue2-filters";
import Constants from "@/plugins/constants";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import { checkFromChild, checkFromParent } from "./utils/checkBackgroundColor";
import "./plugins/vue-device-detector";
import "./filters/status-translate";
import CheckPerms from "./plugins/check-perms";
import VueCookies from "vue-cookies";

//SOCKET IO
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
import { checkPerms } from "./utils/checkPerms";
const socket = io(process.env.VUE_APP_SOCKET_SERVER);
Vue.use(VueSocketIOExt, socket, { store });

const Vue2FiltersConfig = {
  currency: {
    symbol: process.env.VUE_APP_CURRENCY_ICON,
    decimalDigits: process.env.VUE_APP_CURRENCY_PRECISION,
    thousandsSeparator: process.env.VUE_APP_CURRENCY_THOUSANDS_SEPARATOR,
    decimalSeparator: process.env.VUE_APP_CURRENCY_DECIMAL_SEPARATOR,
    symbolOnLeft: process.env.VUE_APP_CURRENCY_SYMBOL_ON_LEFT === "true",
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false,
  },
};

Vue.use(Chartkick.use(Chart));
Vue.config.productionTip = false;
Vue.use(Constants);
Vue.use(Vue2Filters, Vue2FiltersConfig);
Vue.use(CheckPerms);
Vue.use(VueCookies);

/* 
use v-contrast for contrast from parent to children

use v-contrast:child for contrast from children to parent
*/
Vue.directive("contrast", {
  inserted: async function (el, binding, vnode) {
    const arg = binding.arg;
    const checkParent = arg ? arg.toUpperCase() == "PARENT" : true;

    // using this trick to access this element's parent element
    vnode.context?.$nextTick(() => {
      if (checkParent) {
        checkFromParent(el);
      } else {
        checkFromChild(el, arg);
      }
    });
  },
  componentUpdated: function (el, binding, vnode) {
    const arg = binding.arg;
    const checkParent = arg ? arg.toUpperCase() == "PARENT" : true;

    // using this trick to access this element's parent element
    vnode.context?.$nextTick(() => {
      if (checkParent) {
        checkFromParent(el);
      } else {
        checkFromChild(el, arg);
      }
    });
  },
});

Vue.directive("can", function (el, binding) {
  const privileges: string[] = binding.value;
  const type = binding.arg ? binding.arg[0].toUpperCase() : "R";
  const has_permission = checkPerms({
    actions: type as "C" | "U" | "R" | "D",
    entities: privileges,
  });

  const currentStyle = window.getComputedStyle(el);

  if (!has_permission) {
    currentStyle.display != "none" &&
      el.setAttribute("originalDisplay", currentStyle.display);
    el.style.display = "none";
  } else {
    if (currentStyle.display == "none") {
      el.style.display = el.getAttribute("originalDisplay") || "inherit";
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
